import React, {useState, useEffect, useCallback} from 'react';
import {Link} from 'react-router-dom'
import {httpGet} from '../shared/Config';
import {Button, Dropdown, DropdownItem,DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap'
import OrganizationModal from './OrganizationModal'
import SectionList from '../shared/SectionList'
import DepartmentRequestModal from './DepartmentRequestModal';
import BuyGlobalDocumentsModal from './BuyGlobalDocumentsModal'
import AssignDocumentsModal from './AssignDocumentsModal';
import AssignTimestampsModal from './AssignTimestampsModal';
import UpdateRegimenFiscal from '../shared/UpdateRegimenFiscal';
import StripeSessionData from "./StripeSessionData";
import UpdateRegimenFiscalInline from "../shared/UpdateRegimenFiscalInline";
import BuyGlobalDocumentsInline from "./BuyGlobalDocumentsInline";


import { useTranslation } from 'react-i18next';
import BuyGlobalIdentityVerificationsModal from "./BuyGlobalIdentityVerificationsModal";
import BuyGlobalWhatsAppMessages from "./BuyGlobalWhatsAppMessages";

function DropdownItemWithPaymentLabel({children, pendingPayment, ...props}) {
    return (
        <DropdownItem
            {...props}>
            {children}
            {
                pendingPayment ?
                    <span> - Bloqueado por pago pendiente</span>
                    : null
            }
        </DropdownItem>
    )
}

function Organizations({history}) {


    const {t} = useTranslation()
    const [tenant, setTenant] = useState({});
    const [mode, setMode] = useState("ALL");

    const [serverConf, setServerConf] = useState({});
    const [allowPurchases, setAllowPurchases] = useState(false);

    const [companyActive, setCompanyActive] = useState(false);
    const [companyActiveCheck, setCompanyActiveCheck] = useState(false);

    const [companyCheck, setCompanyCheck] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [companyNameNotUpdated, setCompanyNameNotUpdated] = useState(false);

    const [remainingDocs, setRemainingDocs] = useState({});
    const [remainingVerifications, setRemainingVerifications] = useState({});
    const [remainingAllowedVerifications, setRemainingAllowedVerifications] = useState();
    const [pendingLockingVerificationsPayments, setPendingLockingVerificationsPayments] = useState();

    const [remainingWhatsAppMessages, setRemainingWhatsAppMessages] = useState({})
    const [remainingAllowedWhatsAppMessages, setRemainingAllowedWhatsAppMessages] = useState()
    const [pendingLockingWhatsAppMessagesPayments, setPendingLockingWhatsAppMessagesPayments] = useState()


    const [remainingTimestamps, setRemainingTimestamps] = useState({});

    const [accountStatus, setAccountStatus] = useState({});

    const [remainingAllowedDocuments, setRemainingAllowedDocuments] = useState();
    const [pendingLockingDocumentsPayments, setPendingLockingDocumentsPayments] = useState();
    //const [pendingLockingDocumentsPaymentsCheck, setPendingLockingDocumentsPaymentsCheck] = useState(false);


    const [remainingAllowedTimestamps, setRemainingAllowedTimestamps] = useState(false);
    const [pendingLockingTimestampsPayments, setPendingLockingTimestampsPayments] = useState(false);
    //const [pendingLockingTimestampsPaymentsCheck, setPendingLockingTimestampsPaymentsCheck] = useState(false);

    //const [personalOrganization, setPersonalOrganization] = useState([])
    const [organizations, setOrganizations] = useState(null)

    const [amountDepartments, setAmountDepartments] = useState(0);
    //const [allowMoreDepartments, setAllowMoreDepartments] = useState(false);

    const [askFacturacion, setAskFacturacion] = useState(false);
    const [upgradePending, setUpgradePending] = useState(false);
    const [remainingDocsArr, setRemainingDocsArr] = useState([]);

    const [modal, setModal] = useState({
        open: false,
        initialState: null
    });
    const [requestModal, setRequestModal] = useState({
        open: false,
        initialState: null
    });

    const [globalDocumentsModal, setGlobalDocumentsModal] = useState({
        open: false
    })

    const [globalIdentitiesModal, setGlobalIdentitiesModal] = useState(false)
    const [whatsAppMessagesModal, setWhatsAppMessagesModal] = useState(false)

    const [globalTimestampsModal, setGlobalTimestampsModal] = useState({
        open: false
    })

    const [updateRegimenModal, setUpdateRegimenModal] = useState({
        open: false,
        invoiceId: 0
    })


    const fetchPendingPayments = useCallback(async () => {
        const responseDocuments = await httpGet('/payments/checkIfPendingPayments/DOCUMENTS');
        if(responseDocuments){
            setPendingLockingDocumentsPayments(responseDocuments.data.data.pending);

            if(responseDocuments.data.data.pending) {
                setRemainingAllowedDocuments(responseDocuments.data.data.remaining);
            }
        }


        const responseVerifications = await httpGet('/payments/checkIfPendingPayments/IDENTITY_VERIFICATIONS');
        if(responseVerifications){
            setPendingLockingVerificationsPayments(responseVerifications.data.data.pending);

            if(responseVerifications.data.data.pending) {
                setRemainingAllowedVerifications(responseVerifications.data.data.remaining);
            }
        }


        const responseWhatsApp = await httpGet('/payments/checkIfPendingPayments/WHATSAPP');
        if(responseWhatsApp){
            setPendingLockingWhatsAppMessagesPayments(responseWhatsApp.data.data.pending);

            if(responseWhatsApp.data.data.pending) {
                setRemainingAllowedWhatsAppMessages(responseWhatsApp.data.data.remaining);
            }
        }

        const responseTimestamps = await httpGet('/payments/checkIfPendingPayments/TIMESTAMPS');
        if(responseTimestamps){
            setPendingLockingTimestampsPayments(responseTimestamps.data.data.pending);

            if(responseTimestamps.data.data.pending) {
                setRemainingAllowedTimestamps(responseTimestamps.data.data.remaining);
            }
        }
    },[]);

    useEffect(() => {
        fetchPendingPayments()
    }, [fetchPendingPayments]);


    const fetchRemainingGlobalDocuments = useCallback(async () => {
        const response = await httpGet(`/orgs/globalDocuments/remaining`);
        setRemainingDocs({...response.data});

    },[])


    const fetchRemainingGlobalVerifications = useCallback(async () => {
        const response = await httpGet(`/orgs/globalVerifications/remaining`);
        setRemainingVerifications(response.data)

    },[])

    const fetchRemainingWhatsAppMessages = useCallback(async () => {
        const response = await httpGet(`/orgs/globalWhatsApp/remaining`);
        setRemainingWhatsAppMessages(response.data)
    }, [])

    const fetchRemainingGlobalTimestamps = useCallback(async () => {
        const response = await httpGet(`/orgs/globalTimestamps/remaining`);
        console.log('remaining global timestamps: ');
        console.log(JSON.stringify(response.data));
        setRemainingTimestamps({...response.data});

    },[]);

    const fetchAccountStatus = useCallback( async () => {
        const response = await httpGet(`/orgs/accountStanding/find`);
        console.log('account status: ');

        if(response.data) {
            if(response.data.clientType) {
                let cType = response.data.clientType;
                if(cType === "DEMO"){
                    setMode("ALL");
                } else if(cType === "CORPORATIVO") {
                    fetchRemainingWhatsAppMessages();
                    fetchRemainingGlobalVerifications();
                    fetchRemainingGlobalDocuments();
                    fetchRemainingGlobalTimestamps();
                    setMode("PARTIAL");
                } else if(cType === "PERSONAL") {
                    setMode("PARTIAL_PERSONAL");
                } else if(cType === "EMPRESARIAL") {
                    setMode("PARTIAL_EMPRESARIAL");
                }

                if(response.data.data) {
                    setAccountStatus({...response.data.data});
                } else if(response.data) {
                    setAccountStatus({...response.data});
                }
            } else if(response.data.data) {
                if(response.data.upgradePending) {
                    setUpgradePending(true);
                }

                if(response.data.data.status === "no_demo") {
                    //localStorage.removeItem('jwt');
                    //window.location.href = '/login';
                }

                setAccountStatus({...response.data.data});
            }
        }
        fetchPendingPayments()
    },[fetchPendingPayments, fetchRemainingGlobalTimestamps, fetchRemainingGlobalDocuments, fetchRemainingGlobalVerifications, fetchRemainingWhatsAppMessages])

    function handleGlobalDocumentsModalCancel() {
        setGlobalDocumentsModal({open:false})
    }

    async function handleGlobalDocumentsModalClick() {
        try {
            const response = await httpGet('/invoiceData/getGlobalInvoicePresent');
            if(response?.data.status) {
                if(response.data.status === "no_global_invoice") {
                    setAskFacturacion(true);
                    setUpdateRegimenModal({open:true, invoiceId: null});
                } else if(response.data.status === "no_invoice_with_regimen" || response.data.status === "global_selected_not_updated") {
                    setAskFacturacion(true);
                    setUpdateRegimenModal({open:true, invoiceId: response.data.invoiceId});
                }  else if(response.data.status === "invoice_found") {
                    const responseRF = await httpGet(`/getRegimenFiscalStatus`);
                    if(responseRF) {
                        if(responseRF.data.updated) {
                            setGlobalDocumentsModal({open:true})
                        } else if(responseRF.data.invoiceId) {
                            setUpdateRegimenModal({open:true, invoiceId: responseRF.data.invoiceId});
                        }
                    }
                }
            }
        } catch(e) {
        }
    }

    async function handleBuyIdentitiesClick() {
        setGlobalIdentitiesModal(true);
    }

    async function handleBuyWhatsAppMessagesClick() {
        setWhatsAppMessagesModal(true)
    }

    function handleGlobalWhatsAppAdded() {
        fetchAccountStatus();
        setWhatsAppMessagesModal(false)
        // fetchRemainingGlobalVerifications();
    }

    function handleGlobalVerificationsAdded() {
        fetchAccountStatus();
        setGlobalIdentitiesModal(false)
        fetchRemainingGlobalVerifications();
    }

    function handleGlobalDocumentsAdded(paymentType) {
        fetchAccountStatus();
        setGlobalDocumentsModal({open:false});
        fetchRemainingGlobalDocuments(); // gets the global amount of documents
    }

    function handleGlobalTimestampsModalCancel() {
        setGlobalTimestampsModal({open:false})
    }

    function handleGlobalTimestampsModalClick() {
        setGlobalTimestampsModal({open:true})
    }

    function handleGlobalTimestampsAdded() {
        setGlobalTimestampsModal({open:false});
        fetchRemainingGlobalTimestamps(); // gets the global amount of documents
    }

    useEffect(() => {
        async function fetchServerConf() {
            try {
                const response = await httpGet('/getServerConf');
                if(response?.data.jsonData) {
                    let parseConf = JSON.parse(response.data.jsonData);
                    setServerConf(parseConf);
                    setAllowPurchases(parseConf.purchaseActive);
                }
            } catch(e) {
                console.error(e);
            }
        }

        async function fetchAllOrganizations() {
            try {
                const response = await httpGet('/orgsWithPlanFilter');
                let corporativeFound = 0;
                for(let plan of response.data) {
                    if(plan.plan.id === 3 || plan.plan.id === 2) {
                        corporativeFound++;
                    }
                }
                const amount = corporativeFound;
                setAmountDepartments(amount);
                /*if(amount < 5) {
                    setAllowMoreDepartments(true);
                }*/
                setOrganizations(response.data);
            } catch(e) {
                console.error(e);
                setOrganizations([]);
            }
        }

        async function getCompanyN() {
            try {
                const response = await httpGet('/getCompanyName');
                if(response.data){
                    const responseParse = JSON.parse(response.data);
                    if(responseParse.companyName) {
                        setCompanyName(responseParse.companyName);
                    } else if(responseParse.status) {
                        if(responseParse.status === "not_updated" ){
                            setCompanyNameNotUpdated(true);
                        }
                    }
                }
                setCompanyCheck(true);
            } catch(e) {
            }
        }

        /*async function getStatus() {
            try {
                const response = await httpGet(`/getRegimenFiscalStatus`);
                if(response && !response.data.updated && response.data.invoiceId) {
                    setUpdateRegimenModal({open:true, invoiceId: response.data.invoiceId});
                }
            } catch(e) {
            }
        }*/


        async function checkInvoice() {
            const response = await httpGet(`/orgs/check/checkIfPurchasesDone`);
            if(response) {
                if(response.data.status === "UPGRADED" || response.data.status === "ERROR" || response.data.status === "CORPORATIVO") {
                    //
                } else {
                    const responseGlobal = await httpGet('/invoiceData/getGlobalInvoicePresent');
                    if(responseGlobal?.data.status) {
                        if(responseGlobal.data.status === "no_global_invoice" || responseGlobal.data.status === "no_invoice_with_regimen" || responseGlobal.data.status === "global_selected_not_updated") {
                            setAskFacturacion(true);
                        } else if(responseGlobal.data.status === "invoice_found") {
                            setAskFacturacion(false);
                        }
                    }
                }
            }
        }

        async function fetchTenant() {
            try {
                const tenant = await httpGet(`/getTenant`);
                let conf = JSON.parse(tenant.data.data.configuration);
                setTenant(conf);
                console.log(conf);
            } catch(e) {
                console.error(e);
            }
        }

        fetchTenant();
        getCompanyN();
        fetchServerConf();
        fetchAllOrganizations();
        fetchAccountStatus();
        checkCompanyStatus();
        checkInvoice();
        //
        //getStatus();
    }, [fetchAccountStatus,mode])



    useEffect(() => {

        if(!organizations) {
            return;
        }

        for(let org of organizations) {
            if(!org.organization.consumeGlobals) {
                async function getRemaningDocuments(org) {
                    try {
                        const response = await httpGet(`/orgs/${org.id}/documents/remaining`);
                        if(response) {
                            let responseData = response.data;
                            let val = {id: org.id, remaining: responseData.documents} ;
                            setRemainingDocsArr(rem => [...rem, val]);
                        }
                    } catch(e) {
                        console.error(e);
                    }
                }

                getRemaningDocuments(org.organization);
            }
        }
    }, [organizations]);



    async function checkCompanyStatus() {
        const responseStatus = await httpGet('/checkCompanyStatus');
        if(responseStatus){
            setCompanyActive(responseStatus.data.data.active);
            setCompanyActiveCheck(true);
        }
    }



    function handleNewOrg() {
        setModal({initialState: null, open: true, mode: "PARTIAL"})
    }

    function handleAdd() {
        async function checkIfPurchaseDone() {
            // Checks if there isn't a purchase done (spei or stripe)
            // if there is, this cannot be DEMO
            const response = await httpGet(`/orgs/check/checkIfPurchasesDone`);
            if(response) {
                if(response.data.status === "UPGRADED" || response.data.status === "ERROR" || response.data.status === "CORPORATIVO") {
                    window.location.reload(false);
                } else {
                    let modeCheck;
                    if(response.data.status === "NEED_UPGRADE_DATA") {
                        modeCheck = "ONLY_INFO";
                    } else {
                        modeCheck = "ALL";
                    }
                    setMode(modeCheck);

                    const responseGlobal = await httpGet('/invoiceData/getGlobalInvoicePresent');
                    if(responseGlobal?.data.status) {
                        if(responseGlobal.data.status === "no_global_invoice") {
                            setAskFacturacion(true);
                            setUpdateRegimenModal({open:true, invoiceId: null});
                        } else if(responseGlobal.data.status === "no_invoice_with_regimen" || responseGlobal.data.status === "global_selected_not_updated") {
                            setAskFacturacion(true);
                            setUpdateRegimenModal({open:true, invoiceId: responseGlobal.data.invoiceId});
                        } else if(responseGlobal.data.status === "invoice_found") {
                            const responseGlobalRF = await httpGet(`/getRegimenFiscalStatus`);
                            if(responseGlobalRF) {
                                if(responseGlobalRF.data.updated) {
                                    setModal({initialState: null, open: true, mode: modeCheck})
                                } else if(responseGlobalRF.data.invoiceId) {
                                    setUpdateRegimenModal({open:true, invoiceId: responseGlobalRF.data.invoiceId});
                                }
                            }
                        }
                    }
                }
            }
        }
        checkIfPurchaseDone();
    }

    function handleRequest() {
        setRequestModal({initialState: null, open: true})
    }

    function handleAlreadyExists(id) {
        history.push(`/console/orgs/${id}`);
    }

    function handleOrgSaved(newOrg) {
        window.location.reload(false);
    }

    function onSaved(paymentType) {
        setAskFacturacion(false);
        setUpdateRegimenModal({open:false});
    }

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <h3>Hola {companyCheck ?
                        <React.Fragment>{companyName ? <React.Fragment>{companyName}</React.Fragment> :
                            <React.Fragment>{accountStatus.name}</React.Fragment>}</React.Fragment> : null},</h3>
                    <div>
                        <b>Tipo de cuenta:</b> {accountStatus.uiClientType}
                        {
                            accountStatus.clientType === "DEMO" ? <React.Fragment>
                                {
                                    accountStatus.status === "DEMO_ACTIVE" ? <React.Fragment>
                                        <br/>
                                        Tiempo
                                        restante: {accountStatus.daysRemaining} dias, {accountStatus.hoursRemaining} horas
                                        <br/>
                                        <br/>
                                    </React.Fragment> : null
                                }
                                {
                                    accountStatus.status === "DEMO_OVER" ? <React.Fragment>
                                        <br/>
                                        Ha concluido tu periodo de prueba. Por favor contrata un paquete de documentos
                                        <br/>
                                        <br/>
                                    </React.Fragment> : null
                                }
                                <br/>
                                <br/>
                            </React.Fragment> : null
                        }
                    </div>
                    {
                        accountStatus.clientType === "CORPORATIVO" ?
                            <div>
                                <b>Documentos restantes:</b> {remainingDocs.documents}
                                {pendingLockingDocumentsPayments ?
                                    <React.Fragment> (Limitados por pago
                                        pendiente: {remainingAllowedDocuments} disponibles)</React.Fragment>
                                    : null
                                }
                                <br/>
                                <b>Verificaciones de Identidad:</b> {remainingVerifications.amount}
                                {pendingLockingVerificationsPayments ?
                                    <React.Fragment> (Limitadas por pago
                                        pendiente: {remainingAllowedVerifications} disponibles)</React.Fragment>
                                    : null
                                }
                                <br/>
                                <b>Mensajes de WhatsApp: </b> {remainingWhatsAppMessages.amount}
                                {pendingLockingWhatsAppMessagesPayments ?
                                    <React.Fragment> (Limitados por pago
                                        pendiente: {remainingAllowedWhatsAppMessages} disponibles)</React.Fragment>
                                    : null
                                }
                                <br/>
                                {
                                    allowPurchases ?
                                        <UncontrolledDropdown group
                                                              style={{margin: '20px 0'}}>
                                            <DropdownToggle caret color="primary">Comprar </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItemWithPaymentLabel
                                                    disabled={!companyActive || pendingLockingDocumentsPayments}
                                                    pendingPayment={pendingLockingDocumentsPayments}
                                                    onClick={handleGlobalDocumentsModalClick}>
                                                    Documentos
                                                </DropdownItemWithPaymentLabel>
                                                <DropdownItemWithPaymentLabel
                                                    disabled={!companyActive || pendingLockingVerificationsPayments}
                                                    pendingPayment={pendingLockingVerificationsPayments}
                                                    onClick={handleBuyIdentitiesClick}>
                                                    Verificaciones de Identidad
                                                </DropdownItemWithPaymentLabel>
                                                <DropdownItemWithPaymentLabel
                                                    disabled={!companyActive || pendingLockingWhatsAppMessagesPayments}
                                                    pendingPayment={pendingLockingWhatsAppMessagesPayments}
                                                    onClick={handleBuyWhatsAppMessagesClick}>
                                                    Mensajes de WhatsApp
                                                </DropdownItemWithPaymentLabel>
                                            </DropdownMenu>
                                        </UncontrolledDropdown>
                                        : null
                                }
                            </div>
                            : null
                    }
                    {
                        !companyActive && companyActiveCheck ?
                            <p style={{fontWeight: 'bold', color: 'red'}}>Su compañía ha sido desactivada. Por favor,
                                contáctenos para más información. <br/> No podrá comprar documentos/sellos NOM151,
                                solicitar personalización o crear nuevos departamentos.</p>
                            : null
                    }
                    {
                        upgradePending ? <React.Fragment>
                            Aún se está procesando su pago. En unos minutos deben reflejarse los cambios.
                        </React.Fragment> : null
                    }
                    <StripeSessionData></StripeSessionData>
                    <div className="row justify-content-end">
                        {/*{*/}
                        {/*    accountStatus.clientType === "DEMO" ?*/}
                        {/*        <Button disabled={!companyActive || upgradePending} style={{marginRight: '5px'}}*/}
                        {/*                color="primary" onClick={handleAdd}>*/}
                        {/*            {askFacturacion ? <span>Contratar un servicio</span> :*/}
                        {/*                <span>Comprar documentos</span>}*/}
                        {/*        </Button> : null*/}
                        {/*}*/}
                        {
                            accountStatus.clientType === "PERSONAL" ?
                                <Button disabled={!companyActive} style={{marginRight: '5px'}} color="primary"
                                        onClick={handleAdd}>Cambiar servicio a ON DEMAND</Button> : null
                        }
                        { /*accountStatus.clientType === "EMPRESARIAL" */}
                        {

                            accountStatus.clientType === "CORPORATIVO" || accountStatus.clientType === "EMPRESARIAL" ?
                                <Button disabled={!companyActive} color="primary" onClick={handleNewOrg}>
                                    Crear departamento
                                </Button> : null
                        }
                    </div>
                    {/*{
                        accountStatus.clientType === "CORPORATIVO" || accountStatus.clientType === "PERSONAL" ? <React.Fragment>*/}
                    <p>
                        {
                            accountStatus.clientType === "CORPORATIVO" || accountStatus.clientType === "EMPRESARIAL" ?
                                <React.Fragment>
                                    Aquí puedes administrar los departamentos de tu empresa.
                                    <br/>
                                    Departamentos creados: {amountDepartments}
                                </React.Fragment> : null
                        }
                        {
                            accountStatus.clientType === "PERSONAL" ? <React.Fragment>
                                Puedes ir a modificar tu suscripción dando click en la seccion inferior.
                            </React.Fragment> : null
                        }
                    </p>
                    {
                        accountStatus.clientType === 'DEMO' ?
                            askFacturacion ?
                            <UpdateRegimenFiscalInline
                                invoiceId={updateRegimenModal.invoiceId}
                                onSaved={onSaved}
                            ></UpdateRegimenFiscalInline> :
                            <BuyGlobalDocumentsInline
                                onCancel={handleGlobalDocumentsModalCancel}
                                onSaved={handleGlobalDocumentsAdded}
                                companyActive={companyActive}
                                mode="ONLY_DOCS"
                                org={null}
                                pendingPayments={pendingLockingDocumentsPayments}
                                askFacturacion={askFacturacion}
                                tenant={tenant}
                            ></BuyGlobalDocumentsInline>
                            : null
                    }
                    {
                        accountStatus.clientType !== "DEMO" ?
                            <SectionList collection={organizations}
                                         noData={<p>Ninguna</p>}>
                                {
                                    organizations && organizations.map(org => (
                                        <div key={org.organization.id}>
                                            <Link to={`orgs/${org.organization.id}`} style={{cursor: 'pointer'}}>
                                                <p style={{fontWeight: 'bold'}}>{org.organization.organizationName} </p>
                                            </Link>
                                            {
                                                !org.organization.consumeGlobals ? <React.Fragment>
                                                    Este departamento no consume de los documentos globales restantes.
                                                    <br/>
                                                    Documentos restantes: {
                                                    remainingDocsArr.filter(val => val.id === org.organization.id).map(valF => (
                                                        <span key={valF.id}>{valF.remaining}</span>
                                                    ))
                                                }
                                                </React.Fragment> : null
                                            }
                                        </div>
                                    ))
                                }
                            </SectionList> : null
                    }
                    <BuyGlobalDocumentsModal
                        isOpen={globalDocumentsModal.open}
                        onCancel={handleGlobalDocumentsModalCancel}
                        onSaved={handleGlobalDocumentsAdded}
                        companyActive={companyActive}
                        mode="ONLY_DOCS"
                        org={null}
                        pendingPayments={pendingLockingDocumentsPayments}
                        askFacturacion={askFacturacion}
                        tenant={tenant}
                    />

                    <BuyGlobalWhatsAppMessages
                        isOpen={whatsAppMessagesModal}
                        onCancel={() => setWhatsAppMessagesModal(false)}
                        onSaved={handleGlobalWhatsAppAdded}
                        companyActive={companyActive}
                        org={null}
                        tenant={tenant}
                        />

                    <BuyGlobalIdentityVerificationsModal
                        isOpen={globalIdentitiesModal}
                        onCancel={() => setGlobalIdentitiesModal(false)}
                        onSaved={handleGlobalVerificationsAdded}
                        companyActive={companyActive}
                        org={null}
                        tenant={tenant}
                    />
                    {/*<BuyGlobalTimestampsModal
                        isOpen={globalTimestampsModal.open}
                        onCancel={handleGlobalTimestampsModalCancel}
                        onSaved={handleGlobalTimestampsAdded}
                        companyActive={companyActive}
                        pendingPayments={pendingLockingTimestampsPayments}
                    />*/}
                    <OrganizationModal
                        isOpen={modal.open}
                        initialState={modal.initialState}
                        onCancel={() => setModal({initialState: null, open: false})}
                        onSaved={handleOrgSaved}
                        alreadyExists={handleAlreadyExists}
                        companyActive={companyActive}
                        mode={mode}
                        companyName={companyName}
                        companyNameNotUpdated={companyNameNotUpdated}
                        askFacturacion={askFacturacion}
                        tenant={tenant}
                    />
                </div>
                <UpdateRegimenFiscal
                    isOpen={updateRegimenModal.open}
                    invoiceId={updateRegimenModal.invoiceId}
                    onSaved={onSaved}
                ></UpdateRegimenFiscal>
                <DepartmentRequestModal
                    isOpen={requestModal.open}
                    initialState={requestModal.initialState}
                    onCancel={() => setRequestModal({initialState: null, open: false})}
                    companyActive={companyActive} />
            </div>
        </div>
    )
}

function AssignDocuments({orgId, onDocumentsAssigned, orgName}) {

    const [modal, setModal] = useState({
        open: false
    })

    const [planData, setPlanData] = useState({
        plan: {}
    });

    const [remainingDocs, setRemainingDocs] = useState({});

    function handleModalCancel() {
        setModal({open:false})
    }

    function handleAssignDocumentsClick() {
        setModal({open:true})
    }

    function handleDocumentsAssigned() {
        setModal({open:false});
        fetchRemainingDocuments();
        onDocumentsAssigned();
    }

    useEffect(() => {
        window.scrollTo(0,0);
        fetchPlan();
    }, [])


    async function fetchRemainingDocuments() {
        const response = await httpGet(`/orgs/${orgId}/documents/remaining`);
        setRemainingDocs({...response.data});
    }


    async function fetchPlan() {
        const response = await httpGet(`/orgs/${orgId}/plan`);
        setPlanData(response.data);

        if(response.data.plan.name === "CORPORATIVO") {
            fetchRemainingDocuments();
        }
    }

    return (
        <div className="container">
            {
                planData.plan.name === "CORPORATIVO" ?
                    <div className="row align-items-center">
                        <div className="col">
                            <div>Documentos restantes: {remainingDocs.documents}</div>
                        </div>
                        <div className="col">
                            <div className="row justify-content-end">
                            <Button outline
                                onClick={handleAssignDocumentsClick}
                                color="primary">Asignar documentos</Button>
                            </div>
                        </div>
                        <AssignDocumentsModal
                            isOpen={modal.open}
                            onCancel={handleModalCancel}
                            onSaved={handleDocumentsAssigned}
                            orgId={orgId}
                            orgName={orgName}
                        />
                    </div>
                 : null
            }
        </div>
    )
}

function AssignTimestamps({orgId, onTimestampsAssigned, orgName}) {

    const [modal, setModal] = useState({
        open: false
    })

    const [planData, setPlanData] = useState({
        plan: {}
    });

    const [remainingTimestamps, setRemainingTimestamps] = useState({});
    const [stampsActive, setStampsActive] = useState(false);

    function handleModalCancel() {
        setModal({open:false})
    }

    function handleAssignTimestampsClick() {
        setModal({open:true})
    }

    function handleTimestampsAssigned() {
        setModal({open:false});
        fetchRemainingTimestamps();
        onTimestampsAssigned();
    }

    useEffect(() => {
        window.scrollTo(0,0);
        fetchPlan();
    }, [])

    function handleActivateStamps() {
        async function fetchOrgData() {
            const response = await httpGet(`/orgs/${orgId}/config/activateTimestamps`)

            if(response.data) {
                const dataJson = response.data;
                if(dataJson.success) {
                    setStampsActive(true);
                }
            }
        }
        fetchOrgData();
    }

    function checkConfig(){
        async function fetchConfig() {
            try {
                const response = await httpGet(`/orgs/${orgId}/config/nom151FinalStamp`);
                if(response.data.data){
                    const dataJson = response.data.data;
                    if(dataJson.stamp) {
                        setStampsActive(true);
                    } else {
                        setStampsActive(false);
                    }
                }
                //setStamper(serverConfig);
            } catch(e) {
                setStampsActive(false)
            }
        }
        fetchConfig();
    }

    async function fetchRemainingTimestamps() {
        const response = await httpGet(`/orgs/${orgId}/timestamps/remaining`);
        console.log('remaining docs: ');
        console.log(JSON.stringify(response.data));
        setRemainingTimestamps({...response.data});
    }

    async function fetchPlan() {
        const response = await httpGet(`/orgs/${orgId}/plan`);
        setPlanData(response.data);

        if(response.data.plan.name === "CORPORATIVO") {
            fetchRemainingTimestamps();
            checkConfig();
        }
    }

    return (
        <div className="container">
            {
                planData.plan.name === "CORPORATIVO" ? <React.Fragment>
                {
                    stampsActive ?
                        <div className="row align-items-center">
                            <div className="col">
                                <div>Sellos NOM151 restantes: {remainingTimestamps.timestamps}</div>
                            </div>
                            <div className="col">
                                <div className="row justify-content-end">
                                <Button outline
                                    onClick={handleAssignTimestampsClick}
                                    color="primary">Asignar sellos NOM151</Button>
                                </div>
                            </div>
                            <AssignTimestampsModal
                                isOpen={modal.open}
                                onCancel={handleModalCancel}
                                onSaved={handleTimestampsAssigned}
                                orgId={orgId}
                                orgName={orgName}
                            />
                        </div>
                    :
                        <div className="row align-items-center">
                            <div className="col">
                                <div>Las sellos NOM151 estan desactivadas para esta cuenta.</div>
                            </div>
                            <div className="col">
                                <div className="row justify-content-end">
                                <Button outline
                                    onClick={handleActivateStamps}
                                    color="primary">Activar sellos NOM151</Button>
                                </div>
                            </div>
                        </div>

                }
                </React.Fragment> : null
            }
        </div>
    )
}

export default Organizations;
